import { ref, computed } from '@vue/composition-api'
import * as yup from 'yup'
import {
  binaryChoice,
  daylist,
  phoneEnum,
  stateAbbreviations,
  yesno,
  ziptest,
} from '@/lib/common/yup-validation/common-validations'
import { LeagueVolunteerRole } from '@/GeneratedTypes/LeagueVolunteerRole'
import { CoachPracticeAvailability } from '@/GeneratedTypes/CoachPracticeAvailability'
import { CoachGenderGrade } from '@/GeneratedTypes/CoachGenderGrade'
import { LeagueVolunteer } from '@/GeneratedTypes/LeagueVolunteer'
import { IndividualAddress } from '@/GeneratedTypes/IndividualAddress'
import { IndividualPhone } from '@/GeneratedTypes/IndividualPhone'
import { getEmptyIndividualAddress } from '@/lib/support/models/IndividualAddress/data'
import { getEmptyLeagueVolunteer } from '@/lib/support/models/LeagueVolunteer/data'
import { VolunteerRoleUDFValue } from '@/GeneratedTypes/VolunteerRoleUDFValue'
import {
  decrementingPhoneIDStrategy,
  getEmptyPhone,
  PhoneTypeEnum,
} from '@/lib/support/models/IndividualPhone/data'
import { LeagueProductMapping } from '@/lib/support/models/LeagueProgram/data'
import { getGradesListFromAccount } from '@/services/gradeService'
import ordersClient from '@/clients/ordersClient'
import store from '@/store'

export interface XLProduct {
  columnName: string
  value: string
}

export interface VolunteerPartsReturn {
  isCheer: boolean
  isSport: boolean
  volunteer: LeagueVolunteer
  address: IndividualAddress
  roles: LeagueVolunteerRole[]
  phones: IndividualPhone[]
  email: string
  practiceNights: CoachPracticeAvailability[]
  grades: CoachGenderGrade[]
  products: XLProduct[]
  coachLinkFirstName: string | null
  coachLinkLastName: string | null
}

const volunteerImportShapeBase = {
  SportCoach: yesno.default('YES'),
  CheerCoach: yesno.default('NO'),
  FirstName: yup.string().required().max(64),
  MI: yup.string().max(1).default(''),
  LastName: yup.string().max(64).required(),
  StreetAddress: yup.string().required().max(128),
  City: yup.string().required().max(64),
  State: yup.string().oneOf(stateAbbreviations).required(),
  Zip: ziptest.required(),
  DOB: yup.date().required(),
  Gender: yup.string().required().matches(/(M|F)/),
  Email: yup.string().email().required(),
  MobilePhone: yup.string().max(48).matches(phoneEnum),
  WorkPhone: yup.string().max(48).matches(phoneEnum),
  WorkPhoneExt: yup.lazy((v) =>
    yup.string().when('WorkPhoneExt', {
      is: (ext) => (ext ? ext.length > 0 : false),
      then: yup
        .string()
        .max(4, `The maximum length for WorkPhoneExt is 4. ${v} is too long`)
        .matches(phoneEnum, 'WorkPhoneExt must be a number')
        .test('required', 'If WorkPhoneExt is provided, WorkPhone is required', function () {
          if (!this.parent.WorkPhone) return false
          return true
        }),
    })
  ),
  CanPracticeMonday: yup.string().uppercase().oneOf(binaryChoice),
  CanPracticeTuesday: yup.string().uppercase().oneOf(binaryChoice),
  CanPracticeWednesday: yup.string().uppercase().oneOf(binaryChoice),
  CanPracticeThursday: yup.string().uppercase().oneOf(binaryChoice),
  CanPracticeFriday: yup.string().uppercase().oneOf(binaryChoice),
  CanPracticeSaturday: yup.string().uppercase().oneOf(binaryChoice),
  CanPracticeSunday: yup.string().uppercase().oneOf(binaryChoice),
  Notes: yup.string().max(255),
  ChurchName: yup.string().max(64),
  CoachLinkFirstName: yup.lazy((v) =>
    yup.string().when('CoachLinkLastName', {
      is: (lname) => (lname ? lname.length > 0 : false),
      then: yup
        .string()
        .max(32, `${v} is too long`)
        .required(`If CoachLinkLastName is supplied, CoachLinkFirstName is required`),
    })
  ),
  CoachLinkLastName: yup.lazy((v) =>
    yup.string().when('CoachLinkFirstName', {
      is: (lname) => (lname ? lname.length > 0 : false),
      then: yup
        .string()
        .max(32, `${v} is too long`)
        .required(`If CoachLinkFirstName is supplied, CoachLinkLastName is required`),
    })
  ),
  UDF1: yup.string().max(64),
  UDF2: yup.string().max(64),
  UDF3: yup.string().max(64),

  //these will get overriden with real values later.
  FemaleGradesToCoach: yup.array(), //.matches(matchGrades).uppercase(),
  MaleGradesToCoach: yup.array(), //.matches(matchGrades).uppercase(),
  FemaleGradesToCoachCheer: yup.array(), //.matches(matchGrades).uppercase(),
  MaleGradesToCoachCheer: yup.array(), //.matches(matchGrades).uppercase(),
  PoloShirtSize: yup.string(), //.oneOf(sizes),
  CheerPoloShirtSize: yup.string(), //.oneOf(sizes),
  TeeShirtSize: yup.string(), //.oneOf(sizes),
}

const volunteerXLType = yup.object().shape(volunteerImportShapeBase).required()
export type VolunteerXL = yup.InferType<typeof volunteerXLType>

export function useVolunteerImport() {
  const myLeague = ref(store.getters.leagueAbstraction.currentItem)
  const schema = ref<any>(null)

  const validFemaleGrades = computed(() => {
    const retval: string[] = []
    if (myLeague.value.accounts != null) {
      const gradeTypes = getGradesListFromAccount(
        myLeague.value.accounts![0],
        store.getters.leagueAbstraction.leagueUpwardProgramTypeID[0].upwardTypeID!,
        'F'
      )
      const grades = gradeTypes.map((g) => g.upwardTypeID!)
      retval.push(...grades)
    }

    return retval
  })

  const validMaleGrades = computed(() => {
    const retval: string[] = []
    if (myLeague.value.accounts != null) {
      const gradeTypes = getGradesListFromAccount(
        myLeague.value.accounts![0],
        store.getters.leagueAbstraction.leagueUpwardProgramTypeID[0].upwardTypeID!,
        'M'
      )
      const grades = gradeTypes.map((g) => g.upwardTypeID!)
      retval.push(...grades)
    }

    return retval
  })

  const validFemaleCheerGrades = computed(() => {
    const retval: string[] = []
    if (store.getters.leagueAbstraction.hasCheer && myLeague.value.accounts != null) {
      const gradeTypes = getGradesListFromAccount(
        myLeague.value.accounts![0],
        store.getters.leagueAbstraction.leagueUpwardProgramTypeID[1].upwardTypeID!,
        'F'
      )
      const grades = gradeTypes.map((g) => g.upwardTypeID!)
      retval.push(...grades)
    }

    return retval
  })

  const validMaleCheerGrades = computed(() => {
    const retval: string[] = []
    if (store.getters.leagueAbstraction.hasCheer && myLeague.value.accounts != null) {
      const gradeTypes = getGradesListFromAccount(
        myLeague.value.accounts![0],
        store.getters.leagueAbstraction.leagueUpwardProgramTypeID[1].upwardTypeID!,
        'M'
      )
      const grades = gradeTypes.map((g) => g.upwardTypeID!)
      retval.push(...grades)
    }

    return retval
  })

  async function buildSchema(mappings: LeagueProductMapping) {
    const allProducts = (await ordersClient.getLeagueOrderItems(myLeague.value.upwardLeagueID ?? '')) ?? []

    function getSizes(colName: string, gender: string) {
      const retval: string[] = []
      const mapping = mappings.leagueProductMappings.find(
        (x) => x.importColumnName == colName && x.gender == gender
      )
      if (mapping) {
        const prod = allProducts.find((p) => p.id == mapping.productID)
        if (prod) {
          retval.push(...new Set(prod.productColorSizes?.map((x) => x.typeSizeID ?? '')))
        }
      }
      return retval
    }

    const coachSportShirtSizesMale = getSizes('PoloShirtSize', 'M')
    const coachSportShirtSizesFemale = getSizes('PoloShirtSize', 'F')

    const coachCheerShirtSizesMale = getSizes('CheerPoloShirtSize', 'M')
    const coachCheerShirtSizesFemale = getSizes('CheerPoloShirtSize', 'F')

    const teeShirtSizesMale = getSizes('TeeShirtSize', 'M')
    const teeShirtSizesFemale = getSizes('TeeShirtSize', 'F')

    const schemaShape = {
      ...volunteerImportShapeBase,
      FemaleGradesToCoach: yup
        .array()
        .transform((value, orgValue) => (orgValue ? orgValue.split(/[\s,]+/) : []))
        .of(yup.string().oneOf(validFemaleGrades.value)),
      MaleGradesToCoach: yup
        .array()
        .transform((value, orgValue) => (orgValue ? orgValue.split(/[\s,]+/) : []))
        .of(yup.string().oneOf(validMaleGrades.value)),
      FemaleGradesToCoachCheer: yup
        .array()
        .transform((value, orgValue) => (orgValue ? orgValue.split(/[\s,]+/) : []))
        .of(yup.string().oneOf(validFemaleCheerGrades.value)),
      MaleGradesToCoachCheer: yup
        .array()
        .transform((value, orgValue) => (orgValue ? orgValue.split(/[\s,]+/) : []))
        .of(yup.string().oneOf(validMaleCheerGrades.value)),
      PoloShirtSize: mappings.columnNames.includes('PoloShirtSize')
        ? yup.string().when('Gender', {
            is: (g) => g == 'M',
            then: yup.string().oneOf(coachSportShirtSizesMale),
            otherwise: yup.string().oneOf(coachSportShirtSizesFemale),
          })
        : yup.string(),

      CheerPoloShirtSize: mappings.columnNames.includes('CheerPoloShirtSize')
        ? yup.string().when('Gender', {
            is: (g) => g == 'M',
            then: yup.string().oneOf(coachCheerShirtSizesMale),
            otherwise: yup.string().oneOf(coachCheerShirtSizesFemale),
          })
        : yup.string(),

      TeeShirtSize: mappings.columnNames.includes('TeeShirtSize')
        ? yup.string().when('Gender', {
            is: (g) => g == 'M',
            then: yup.string().oneOf(teeShirtSizesMale),
            otherwise: yup.string().oneOf(teeShirtSizesFemale),
          })
        : yup.string(),
    }

    schema.value = yup.object().shape(schemaShape).required()
  }

  async function validateVolunteer(
    row: Record<string, unknown>,
    showAllErrors = false
  ): Promise<VolunteerXL | undefined> {
    try {
      if (await schema.value.validate(row, { abortEarly: !showAllErrors })) {
        return schema.value.cast(row)
      }
    } catch (e) {
      throw e
    }
  }

  function extractGrades(gender: string, list: string[]) {
    const grade_part: CoachGenderGrade[] = []
    list.forEach((x) => {
      grade_part.push({
        typeGradeID: x,
        genderToCoach: gender,
      })
    })

    return grade_part
  }

  /*
     validPracticeNights: Allowable practice nights for the league. Camps always pass an empty array.
  */
  function VolunteerXLToVolunteerParts(
    p: VolunteerXL,
    productColumnNames: string[],
    validPracticeNights: string[]
  ): VolunteerPartsReturn {
    const days: CoachPracticeAvailability[] = []
    daylist.forEach((x) => {
      //skip practice nights in xsl that are invalid for league
      if (validPracticeNights.indexOf(x) < 0) return

      //@ts-ignore indexing into the type like this is a bit hackish
      if (p['CanPractice' + x] == 'TRUE' || p['CanPractice' + x] == 'YES') {
        days.push({
          typeDayID: x,
          endTime: '23:59:00',
          startTime: '00:00:01',
        })
      }
    })

    const grades = [
      ...extractGrades('F', (p.FemaleGradesToCoach ?? []) as string[]),
      ...extractGrades('M', (p.MaleGradesToCoach ?? []) as string[]),
      ...extractGrades('F', (p.FemaleGradesToCoachCheer ?? []) as string[]),
      ...extractGrades('M', (p.MaleGradesToCoachCheer ?? []) as string[]),
    ]

    const udfs: VolunteerRoleUDFValue[] = []
    ;['1', '2', '3'].forEach((x) => {
      const val = (p as Record<string, unknown>)['UDF' + x]
      if (val) {
        udfs.push({
          udfid: parseInt(x),
          udfValue: val as string,
        })
      }
    })

    const products = productColumnNames
      .map((c) => {
        const obj = {} as XLProduct
        obj.columnName = c
        //@ts-ignore
        obj.value = p[c]
        return obj
      })
      .filter((p) => p.value) //remove products not provided in spreadsheet

    const volunteerParts = {
      isCheer: (p.CheerCoach == 'YES' || p.CheerCoach == 'TRUE') ?? false,
      isSport: (p.SportCoach == 'YES' || p.SportCoach == 'TRUE') ?? false,
      address: {
        ...getEmptyIndividualAddress(),
        postalCode: p.Zip,
        street1: p.StreetAddress,
        subdivision1: p.City,
        subdivision2: p.State,
      },
      volunteer: {
        ...getEmptyLeagueVolunteer(),
        coachPreferences: null,
        thirdPartyClientIDs: [],
        phoneNumbers: [] as IndividualPhone[],
        emailAddresses: [],
        addresses: [],
        firstName: p.FirstName,
        lastName: p.LastName,
        individualID: 0,
        middleInitial: p.MI ?? null,
        leagueID: 0,
        accountNumber: '',
        birthDate: p.DOB as Date,
        gender: p.Gender,
        roles: [],
        churchName: p.ChurchName ?? null,
        registrationNote: p.Notes ?? null,
        typeSizeID: null,
      },
      phones: [],
      email: p.Email,
      grades: grades,
      products: products,
      practiceNights: days,
      roles: [
        { roleID: 3, upwardAuthInvitationID: '', authorizedExpireDate: null, udFs: udfs, approved: false },
      ],
      coachLinkFirstName: '',
      coachLinkLastName: '',
    }

    if (p.MobilePhone) {
      volunteerParts.volunteer.phoneNumbers.push({
        ...getEmptyPhone(decrementingPhoneIDStrategy()),
        phoneNumber: p.MobilePhone,
        typePhoneID: PhoneTypeEnum.MOBILE,
      })
    }
    if (p.WorkPhone) {
      volunteerParts.volunteer.phoneNumbers.push({
        ...getEmptyPhone(decrementingPhoneIDStrategy()),
        phoneNumber: p.WorkPhone,
        typePhoneID: PhoneTypeEnum.WORK,
        extension: p.WorkPhoneExt ? p.WorkPhoneExt : '',
      })
    }
    const coachLinkFirstName = p.CoachLinkFirstName?.trim()
    const coachLinkLastName = p.CoachLinkLastName?.trim()
    if (coachLinkFirstName && coachLinkLastName) {
      volunteerParts.coachLinkFirstName = coachLinkFirstName
      volunteerParts.coachLinkLastName = coachLinkLastName
    }

    return volunteerParts
  }

  return {
    buildSchema,
    validateVolunteer,
    VolunteerXLToVolunteerParts,
  }
}
